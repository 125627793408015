import React, { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { v4 as uuid } from "uuid";
import "./courseContent.css"
import { deleteModuleContent, fetchCourseModuleList, fetchModuleContent, updateModuleContent, updateModules } from '../../../service/module';
import apiFunctions from '../../../service/axiosConfig/apiFunctions';
import { baseUrl } from '../../../constants';
import Modal from 'react-modal';
import CustomToast from '../../Toast';
import 'react-image-crop/dist/ReactCrop.css';
//import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// MyUploadAdapter.js
class MyUploadAdapter {
    constructor(loader, onFileUpload) {
        this.loader = loader;
        this.onFileUpload = onFileUpload;
    }
    upload() {
        return this.loader.file
            .then((file) => {
                // Implement your file upload logic here
                // For demonstration purposes, log the file name and size
                // Call the function passed to the constructor
                this.onFileUpload(file);

                return new Promise((resolve, reject) => {
                    resolve({ file });
                });
            })
            .catch((error) => {
                console.error('Error uploading file:', error);
                CustomToast.error('Error uploading file');
                return Promise.reject('Error uploading file');
            });
    }
}

const CourseContent = (props) => {
    const [show, setShow] = useState(false);
    const [isModuleInputVisible, setIsModuleInputVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [insightModalIsOpen, setInsightModalIsOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isSubModuleInputVisible, setIsSubModuleInputVisible] = useState(false);
    const [submoduleName, setSubmoduleName] = useState('');
    const [maduleName, setModuleName] = useState('');
    const [maduleEditName, seteditModuleName] = useState('');
    const [maduleList, setModuleList] = useState([]);
    const [selectedSubModule, setSelectedSubModule] = useState('')
    const [selectedEditModule, setselectedEditModule] = useState({})
    const [selectedModule, setSelectedModule] = useState({})
    const [courseContent, setcourseContent] = useState('')
    const [file_path, setFile_path] = useState('')
    const [selectedContentType, setSelectedContentType] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [selectedVFile, setSelectedVFile] = useState(null);
    const [previewVUrl, setPreviewVUrl] = useState(null);
    const [trance, setTrance] = useState(null);
    const [url, setUrl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [maxAttempt, setMaxAttempt] = useState(1);
    const [passingPercentages, setPassingPercentages] = useState(0);
    const [testType, setTestType] = useState('base_line');
    const [contentType, setcontentType] = useState('general');
    const [isEligible, setIsEligible] = useState(true);
    const [courseID,setCourseId] = useState('')
    const [tags,setTags] =useState('')
    const [tagsQuestion,setQuestionTags] =useState('')
    const [data, setData] = useState([]);
    const [subModules, setSubModules] = useState(data?.sub_modules || []);
    const [moduleSubModulesMap, setModuleSubModulesMap] = useState({});
    
    const [courseName, setCourseName] = useState('')

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [markerForms, setMarkerForms] = useState([
        {
            video_marking: '',
            question_type: 'single_choice',
            ans_view: 'show_ans',
            content: '',
            options: [{}],
            correctAnswer: '',
            crt_ans_exp: '',
            wrng_ans_exp: '',
            sub_questions: [{
                video_marking: '',
                question_type: 'single_choice',
                ans_view: 'show_ans',
                content: '',
                options: [{}],
                correctAnswer: '',
                crt_ans_exp: '',
                wrng_ans_exp: '',
                marks: 1,
                rank: 1
            }],
            rating: {min:0,max:0,rating:0},
            marks: 1,
            rank: 1,
            question_tag: ''
        },
    ]);

    const handleAddMarkerForm = (i) => {
        setMarkerForms([
            ...markerForms,
            {
                video_marking: '',
                question_type: 'single_choice',
                ans_view: 'show_ans',
                content: '',
                options: [{}],
                correctAnswer: '',
                crt_ans_exp: '',
                wrng_ans_exp: '',
                sub_questions: [{
                    video_marking: '',
                    question_type: 'single_choice',
                    ans_view: 'show_ans',
                    content: '',
                    options: [{}],
                    correctAnswer: '',
                    crt_ans_exp: '',
                    wrng_ans_exp: '',
                    marks: 1,
                    rank: 1
                }],
                rating: {min:0,max:0,ans:0},
                marks: 1,
                rank: i,
            },
        ]);

        // submitForm()
    };

    const handleSubAddMarkerForm = (i, formIndex) => {
        const updatedForms = [...markerForms];
        updatedForms[i].sub_questions.push({
            video_marking: '',
            question_type: 'single_choice',
            ans_view: 'show_ans',
            content: '',
            options: [{}],
            correctAnswer: '',
            crt_ans_exp: '',
            wrng_ans_exp: '',
            marks: 1, 
            rank: formIndex
        })
        setMarkerForms(updatedForms);
    };

    const handleEditorChange = (event, editor, index) => {
        const updatedForms = [...markerForms];
        updatedForms[index].content = editor.getData();
        setMarkerForms(updatedForms);
    };

    const handleSubEditorChange = (event, editor, index, formIndex) => {
        const updatedForms = [...markerForms];
        // updatedForms[formIndex].sub_questions[index].options.push({});
        updatedForms[index].sub_questions[formIndex].content = editor.getData();
        setMarkerForms(updatedForms);
    };

    const handleTranceEditorChange = (event, editor) => {
        let data = editor?.getData();
        setTrance(data);
    };

    const handleOptionChange = (optionIndex, event, formIndex) => {
        const updatedForms = [...markerForms];
        const unique_id = uuid();
        updatedForms[formIndex].options[optionIndex].content = event.target.value;
        updatedForms[formIndex].options[optionIndex].id = formIndex
        updatedForms[formIndex].options[optionIndex].is_correct = event.target.value === updatedForms[formIndex].correctAnswer
        setMarkerForms(updatedForms);
    };

    const handleAddOption = (formIndex) => {
        const updatedForms = [...markerForms];
        updatedForms[formIndex].options.push({});
        setMarkerForms(updatedForms);
    };

    const handleParaAddOption = (formIndex, index) => {
        const updatedForms = [...markerForms];
        updatedForms[formIndex].sub_questions[index].options.push({});
        setMarkerForms(updatedForms);
    };

    // const handleCorrectAnswerChange = (event, formIndex) => {
    //     const updatedForms = [...markerForms];
    //     updatedForms[formIndex].correctAnswer = event.target.value;
    //     setMarkerForms(updatedForms);
    // };

    const handleCorrectAnswerExplanationChange = (event, formIndex) => {
        const updatedForms = [...markerForms];
        updatedForms[formIndex].crt_ans_exp = event.target.value;
        setMarkerForms(updatedForms);
    };

    const handleWrongAnswerExplanationChange = (event, formIndex) => {
        const updatedForms = [...markerForms];
        updatedForms[formIndex].wrng_ans_exp = event.target.value;
        setMarkerForms(updatedForms);
    };

    const handleminChange = (event, formIndex) => {
        const updatedForms = [...markerForms];
        updatedForms[formIndex].rating.min = event.target.value;
        setMarkerForms(updatedForms);
    };

    const handlemaxChange = (event, formIndex) => {
        const updatedForms = [...markerForms];
        updatedForms[formIndex].rating.max = event.target.value;
        setMarkerForms(updatedForms);
    };

    const handleintervalChange = (event, formIndex) => {
        const updatedForms = [...markerForms];
        updatedForms[formIndex].rating.interval = event.target.value;
        setMarkerForms(updatedForms);
    };

    const handleansChange = (event, formIndex) => {
        const updatedForms = [...markerForms];
        updatedForms[formIndex].rating.ans = event.target.value;
        setMarkerForms(updatedForms);
    };

    const handleSubCorrectAnswerExplanationChange = (event, index, formIndex) => {
        const updatedForms = [...markerForms];
        updatedForms[formIndex].sub_questions[index].crt_ans_exp = event.target.value;
        setMarkerForms(updatedForms);
    };
    
    const handleSubWrongAnswerExplanationChange = (event, index, formIndex) => {
        const updatedForms = [...markerForms];
        updatedForms[formIndex].sub_questions[index].wrng_ans_exp = event.target.value;
        setMarkerForms(updatedForms);
    };

    const openEditModal = (data) => {
        seteditModuleName(data.title)
        setselectedEditModule(data)
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
    };

    const openDeleteModal = (data) => {
        setselectedEditModule(data)
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
    };

    const handleAddModuleClick = () => {
        setIsModuleInputVisible(true);
    };

    const handleAddSubModuleClick = (id) => {
        setSelectedSubModule(id)
        setIsSubModuleInputVisible(true);
    };

    const updateModule = (event, submoduleId, index) => {
        if (event.key === 'Enter') {
            const unique_id = uuid();
            if (submoduleId) {
                let data = {
                    addedModules: [
                        {
                            title: submoduleName,
                            common_module_id: unique_id,
                            rank: index,
                            parent_module_id: selectedSubModule
                        }
                    ],
                    course_meta_id: sessionStorage.getItem('courseId')
                }
                updateModules(data).then(() => {
                    getCourseModuleList()
                    setIsSubModuleInputVisible(false)
                    setIsModuleInputVisible(false)
                    setModuleName('')
                    setSubmoduleName('')

                })
            }
            else {
                let data = {
                    addedModules: [
                        {
                            title: maduleName,
                            common_module_id: unique_id,
                            rank: index,
                        }
                    ],
                    course_meta_id: sessionStorage.getItem('courseId')
                }
                updateModules(data).then(() => {
                    getCourseModuleList()
                    setIsModuleInputVisible(false)
                    setIsSubModuleInputVisible(false)
                    setModuleName('')
                    setSubmoduleName('')
                })
            }
        }
    }

    const editModule = () => {
        let data = {
            editedModules: [
                {
                    title: maduleEditName,
                    _id: selectedEditModule._id,
                }
            ],
            course_meta_id: selectedEditModule.course_meta_id
        }
        updateModules(data).then((data) => {
            getCourseModuleList()
            CustomToast.success("Content saved successfully")
        }).catch((error)=> {
            CustomToast.error("Error while save content")
        })
    }

    const DeleteModule = () => {
        deleteModuleContent(selectedEditModule.common_module_id).then((data) => {
            getCourseModuleList()
            CustomToast.success("Module deleted successfully")
        }).catch((error)=> {
            CustomToast.error("Error while delete module")
        })
    }

    const getCourseModuleList = async () => {
        try {
            const course = await fetchCourseModuleList(sessionStorage.getItem('courseId'));
            if (course.status) {
                setModuleList(course.data);
                const updatedModuleSubModulesMap = {};
                course.data.forEach(module => {
                updatedModuleSubModulesMap[module._id] = module.sub_modules;
                });
                setModuleSubModulesMap(updatedModuleSubModulesMap);

            } else {
                console.error("Error fetching courses:", course.error);
                CustomToast.error('Error while fetching courses')
            }
        } catch (error) {
            console.error("An unexpected error occurred:", error);
            CustomToast.error(error)
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newCourseDetail = sessionStorage.getItem("courseId");
            let courseName = sessionStorage.getItem("courseName")
            setCourseName(courseName)
           
            if (JSON.stringify(newCourseDetail) !== JSON.stringify(courseID)) {
                getCourseModuleList()
                setCourseId(newCourseDetail)
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, [courseID]);
    // useEffect(() => {
    //     getCourseModuleList()
    //     let courseName = sessionStorage.getItem("courseName")
    //     setCourseName(courseName)
    // }, [])
    const editorConfiguration = {
        // plugins: [ Essentials, Bold, Italic, Paragraph ],
        disabeled: true,
        toolbar: []
    };

    const submitForm = async(type) => {
        let data = {}
        switch (selectedContentType) {
            case 'content':
                data.type = selectedContentType;
                data.content = courseContent;
                data.content_type=contentType
                // data.url = file_path;
                break;
            case 'image':
                data.type = selectedContentType;
                data.url = file_path;
                break;
            case 'video':
                data.transcript = trance;
                data.type = selectedContentType;
                if(file_path){
                data.url = file_path;}
                data.addedQuestions = markerForms;
                break;
            case 'test':
                data.content = courseContent;
                data.type = selectedContentType;
                data.addedQuestions = markerForms;
                data.max_attempts = maxAttempt;
                data.passing_percent=passingPercentages;
                data.test_skipable= isEligible;
                data.course_test_type= testType;
                data.test_tag=tags
                break;

            // default:
            //   componentToRender = <DefaultComponent />;
        }
        // if (selectedContentType == "test") {
        //     console.log("fdsvcd");
        //     if (!data.max_attempts
        //         || !data.passing_percent) {
        //         CustomToast.error('Please fill required fields.');
        //         return
        //     }
        // }
        await updateModuleContent(type, selectedModule._id, data)
        getModuleContent(selectedModule)
    }
    window.submitForm = submitForm;




    const deleteSection = async (id) => {
        console.log(selectedModule._id,"fffff",id);
        let data = {
            deletedQuestions: [id?.qid]
        }
        if(id?.qid){
        updateModuleContent('',selectedModule._id, data).then(() => {
            getModuleContent(selectedModule)
        })
    }
    }

    const handleAdditionalEditorChange = (data) => {
        setcourseContent(data)
    };


    const handleFileChange = async (event) => {
        const file = event.target.files[0];

        await setSelectedFile(file);
        // Show preview
        if (file) {

            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setPreviewUrl(null);
        }

    };

    const handleImageUpload = async (e, name, i,oi) => {
        if (e) {
            try {
                // Calling the new function to upload the rating
                const result = await apiFunctions.uploadImage(`${baseUrl}uploadImage`, e);
                await setFile_path(result.data.file_path)
                // Update the courseContent with the image URL
                if (name == "content") {
                    console.log("fffff",result.data.file);
                    setcourseContent((prevContent) => {
                        return prevContent.replace(/<figure class="image"><img><\/figure>/g, `<figure className="image"><img src="${result.data.file}"></figure>`);
                    });
                }
                else if (name == "trance") {
                    setTrance((prevContent) => {
                        return prevContent.replace(/<figure class="image"><img><\/figure>/g, `<figure className="image"><img src="${result.data.file}"></figure>`);
                    });
                }
                else if(name == "Q"){
                    const updatedForms = [...markerForms];
                    updatedForms[i].content=updatedForms[i].content.replace(/<figure class="image"><img><\/figure>/g, `<figure className="image"><img src="${result.data.file}"></figure>`);
                    setMarkerForms(updatedForms);
                }
                else if(name == "O"){
                    const updatedForms = [...markerForms];
                    updatedForms[i].options[oi].content= updatedForms[i].options[oi].content.replace(/<figure class="image"><img><\/figure>/g, `<figure className="image"><img src="${result.data.file}"></figure>`);
                    setMarkerForms(updatedForms);
                }


                return result.data.file_path;
            } catch (error) {
                console.error('Image upload error:', error);
                CustomToast.error('Error while uploading file');
            }

        }
        else {
            //alert('Please select an image');
            CustomToast.error('Please select an image');
            return;
        }

    };

    const handleFileVChange = (event) => {
        const file = event.target.files[0];

        setSelectedVFile(file);

        // Show preview
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewVUrl(reader.result);
            };
            reader.readAsArrayBuffer(file);
            // handleVideoUpload()
        } else {
            setPreviewVUrl(null);
        }
    };

    const handleVideoUpload = async () => {
        if (!selectedVFile) {
            // alert('Please select a video');
            CustomToast.error('Please select a video');
            return;
        }

        try {
            const result = await apiFunctions.uploadVideo(`${baseUrl}uploadVideo`, selectedVFile);
            setFile_path(result.data.file_path)
            CustomToast.success('Video uploaded successfully');
        } catch (error) {
            console.error('Video upload error:', error);
            CustomToast.error('Video upload error');
        }
    };

    const getModuleContent = (data) => {
        setSelectedModule(data)
        fetchModuleContent(data._id).then((data) => {
            if (data.status == true) {
                switch (data?.data?.type) {
                    case 'content':
                        setSelectedContentType(data?.data?.type)
                        setcourseContent(data?.data?.content)
                        data.type = selectedContentType;
                        data.content = courseContent;
                        // data.url = file_path;
                        break;
                    case 'image':
                        setSelectedContentType(data?.data?.type)
                        break;
                    case 'video':
                        setSelectedContentType(data?.data?.type)
                        setUrl(data?.data?.url)
                        setTrance(data?.data?.transcript)
                        if (data?.data?.questions)
                            setMarkerForms(data?.data?.questions)
                        else {
                            setMarkerForms({
                                video_marking: '',
                                question_type: 'single_choice',
                                ans_view: 'show_ans',
                                content: '',
                                options: [{}],
                                correctAnswer: '',
                                sub_questions: [{
                                    video_marking: '',
                                    question_type: 'single_choice',
                                    ans_view: 'show_ans',
                                    content: '',
                                    options: [{}],
                                    correctAnswer: '',
                                    crt_ans_exp: '',
                                    wrng_ans_exp: '',
                                    marks: 1,
                                    rank: 1
                                }],
                                rating: {min:0,max:0,ans:0},
                                crt_ans_exp: '',
                                wrng_ans_exp: '',
                                marks: 1,
                                rank: 1
                            })

                        }
                        break;
                    case 'test':
                        setMaxAttempt(data?.data?.max_attempts)
                        setPassingPercentages(data?.data?.passing_percent)
                        setSelectedContentType(data?.data?.type)
                        setcourseContent(data?.data?.content)
                        setTestType(data?.data?.course_test_type)
                        setIsEligible(data?.data?.test_skipable)
                        setTags(data?.data?.test_tag)
                        if (data?.data?.questions)
                            setMarkerForms(data?.data?.questions)
                        else {
                            setMarkerForms({
                                video_marking: '',
                                question_type: 'single_choice',
                                ans_view: 'show_ans',
                                content: '',
                                options: [{}],
                                correctAnswer: '',
                                crt_ans_exp: '',
                                sub_questions: [{
                                    video_marking: '',
                                    question_type: 'single_choice',
                                    ans_view: 'show_ans',
                                    content: '',
                                    options: [{}],
                                    correctAnswer: '',
                                    crt_ans_exp: '',
                                    wrng_ans_exp: '',
                                    marks: 1,
                                    rank: 1
                                }],
                                rating: {min:0,max:0,ans:0},
                                wrng_ans_exp: '',
                                marks: 1,
                                rank: 1,
                                maxAttempt:'',
                                passingPercentages:'',
                                question_tag: ''
                            })

                        }
                        break;

                    default:
                        setSelectedContentType('')
                }
            }
        })
    }


    const handleSave = async () => {
        await editModule()
        closeEditModal();
    };
    const handleSaveDelete = async () => {
        await DeleteModule()
        closeDeleteModal();
    };

    const handleCheckboxChange = (event, formIndex) => {
        const updatedForms = [...markerForms];
        const optionIndex = event.target.id.split('-')[1]; // Extracting option index from the event target id

        // For single-choice questions, uncheck all other options when one is checked
        if (updatedForms[formIndex].question_type === 'single_choice') {
            updatedForms[formIndex].options.forEach((option, index) => {
                updatedForms[formIndex].options[index].is_correct = index === parseInt(optionIndex);
            });
        } else {
            // For multi-choice questions, toggle the selected option
            updatedForms[formIndex].options[optionIndex].is_correct = event.target.checked;
        }

        setMarkerForms(updatedForms);
    };
    const handleSubCheckboxChange = (event, index, subIndex, suboptionIndex) => {
        const updatedForms = [...markerForms];
        const optionIndex = event.target.id.split('-')[1]; // Extracting option index from the event target id
        let q = updatedForms[index]?.sub_questions[subIndex]
        // For single-choice questions, uncheck all other options when one is checked
        if (updatedForms[index]?.sub_questions[subIndex]?.question_type === 'single_choice') {
            updatedForms[index]?.sub_questions[subIndex]?.options.forEach((option, index) => {
                q.options[index].is_correct = index === parseInt(optionIndex);
            });
        } else {
            // For multi-choice questions, toggle the selected option
            updatedForms[index].sub_questions[subIndex].options[optionIndex].is_correct = event.target.checked;
        }

        setMarkerForms(updatedForms);
    };

    const handleCorrectAnswerChange = (event, formIndex) => {
        const updatedForms = [...markerForms];
        const correctAnswer = event.target.value;

        // Ensure that correct answer is a valid option
        const isValidOption = updatedForms[formIndex].options.some((option) => option.content === correctAnswer);

        if (isValidOption) {
            updatedForms[formIndex].correctAnswer = correctAnswer;
            setMarkerForms(updatedForms);
        } else {
            // Handle invalid correct answer (optional)
            console.error('Invalid correct answer:', correctAnswer);
            CustomToast.error('Invalid correct answer:', correctAnswer);
        }
    };

    const handleCKEditorChange = (optionIndex, event, editor, formIndex) => {
        const updatedForms = [...markerForms];
        updatedForms[formIndex].options[optionIndex].content = editor.getData();
        setMarkerForms(updatedForms);
    };

    const handlesubCKEditorChange = (index, optionIndex, event, editor, formIndex) => {
        const updatedForms = [...markerForms];
        updatedForms[index].sub_questions[formIndex].options[optionIndex].content = editor.getData();
        setMarkerForms(updatedForms);
    };


    const reset = () => {
        setMarkerForms(
            [{
                video_marking: '',
                question_type: 'single_choice',
                ans_view: 'show_ans',
                content: '',
                options: [{}],
                correctAnswer: '',
                crt_ans_exp: '',
                wrng_ans_exp: '',
                sub_questions: [{
                    video_marking: '',
                    question_type: 'single_choice',
                    ans_view: 'show_ans',
                    content: '',
                    options: [{}],
                    correctAnswer: '',
                    crt_ans_exp: '',
                    wrng_ans_exp: '',
                    marks: 1,
                    rank: 1
                }],
                rating: {min:0,max:0,ans:0},
                marks: 1,
                rank: 1,
                question_tag: ''
              
            }]
        );
        setMaxAttempt('')
        setPassingPercentages('')
        setTrance('');
        setcourseContent('')
    }

    const handleTestTypeChange = (event) => {
        setTestType(event.target.value);
    };

    const handleCOntetnTypeChange = (event) => {
        setcontentType(event.target.value);
    };

    const handleEligibilityChange = (event) => {
        setIsEligible(event.target.value);
    };

  /*  const handleModuleDrop = (result, data) => {
        if (!result.destination) {
          return;
        }
      
        const updatedModules = Array.from(data.sub_modules);
        const [movedModule] = updatedModules.splice(result.source.index, 1);
        updatedModules.splice(result.destination.index, 0, movedModule);
      
        setModuleList(updatedModules);
      }; */
      //main 
      const moveSubModules = (currentIndex, targetIndex, subModules) => {
        // Ensure valid indices
        let cModuleId;
        let updatedModule = []
        let promiseArr = []
        if (
          currentIndex < 0 ||
          targetIndex < 0 ||
          currentIndex >= subModules.length ||
          targetIndex >= subModules.length
        ) {
          console.error("Invalid index provided.");
          return;
        }
      
        // Create a copy of the subModules array
        const updatedSubModules = [...subModules];
      
        // Swap submodules at currentIndex and targetIndex
        const temp = updatedSubModules[currentIndex];
        updatedSubModules[currentIndex] = updatedSubModules[targetIndex];
        updatedSubModules[targetIndex] = temp;

        const [firstModule, secondModule] = [updatedSubModules[currentIndex], updatedSubModules[targetIndex]];
        if (firstModule && secondModule) {
            const tempRank = firstModule.rank;
            firstModule.rank = secondModule.rank;
            secondModule.rank = tempRank;
            updatedModule.push(firstModule)
            updatedModule.push(secondModule)
        }
    
        // Update the state with the updated submodules
        setSubModules(updatedSubModules);
      
        // Update the moduleList with the updated submodules
        const updatedModuleList = maduleList.map((module) => {
            const matchData = updatedSubModules.find((ele => ele.parent_id == module._id))
          if (matchData) {
            cModuleId = matchData?.common_module_id
            return { ...module, sub_modules: updatedSubModules };
          } else {
            return module;
          }
        });
      
        // Update the moduleList state with the new list
        setModuleList(updatedModuleList);
      
        // Example of calling updateModules function (assuming this function is defined)
        if(updatedModule.length > 0 ) {
            promiseArr = updatedModule.map((ele=> {
                const payload = {
                    rank : ele.rank,
                    _id: ele._id 
                }
                return payload
            }))

            const finalPayload = {
                editedModules: promiseArr,
                course_meta_id: updatedSubModules[0]?.course_meta_id
            }
            updateModules(finalPayload).then((data) => {
                getCourseModuleList()
                CustomToast.success("Content saved successfully")
            }).catch((error)=> {
                CustomToast.error("Error while save content")
            })
        }
      };

    return (
        <>
          <aside className={`course_details_sidebar sidebar ${show ? "review" : " "} `} id="admin-dash-nav">
                <div className='d-flex flex-column justify-content-between'>
                    {console.log(courseContent)}
                    <div class="row mb-4"><div class="col-md-12"><h5>{courseName}</h5></div></div>
                    <div className='row'>
                        <div className="course-modules">
                            <div className=' justify-content-between w-100 align-items-center' style={{ display: 'block!important' }}>
                                {maduleList && maduleList?.map((data, index) => {

                                     const subModules = data && data?.sub_modules.map((ele => {
                                        ele['parent_id'] = data?._id
                                        return ele
                                     }))

                                    return <div style={{ border: "1px solid #a9a9a9f4", marginBottom: "12px" }}>
                                        <div className='module-box d-flex justify-content-between align-items-center' onClick={() => getModuleContent(data)}>
                                            <h5><img src="/images/module.svg"></img> {data.title}</h5>

                                            <div className="d-flex align-items-center justify-content-center text-decoration-none cursor_pointer dropdown-toggle" id="dropdownUser3" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src="/images/add-more.png"></img>
                                            </div>

                                            <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser3">
                                                <li>
                                                    <button className="dropdown-item" onClick={() => handleAddSubModuleClick(data.common_module_id)}>
                                                        <img src="/images/add.png"></img>Add
                                                    </button>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" onClick={() => openEditModal(data)}>
                                                        <img src="/images/edit.svg" style={{ width: '18px' }}></img>Edit
                                                    </button>
                                                    <Modal
                                                        isOpen={isEditModalOpen}
                                                        onRequestClose={closeEditModal}
                                                        contentLabel="Edit Modal"
                                                        className='editModal'
                                                    >
                                                        <div className='modulename-edit-form p-4 w-75 card'>
                                                            <form>
                                                                <label className='w-100 mb-2'>
                                                                    Edit Module Name: </label>
                                                                <input
                                                                    type="text"
                                                                    value={maduleEditName}
                                                                    className='form-control w-100'
                                                                    onChange={(e) => seteditModuleName(e.target.value)}

                                                                />

                                                                <div className='d-flex justify-content-between align-items-center mt-4'>
                                                                    <button type="button" className='btn btn-md w-100 p-2 btn-secondary me-2' onClick={closeEditModal}>
                                                                        Cancel
                                                                    </button>
                                                                    <button type="button" className='btn btn-md w-100 p-2 btn-primary' onClick={handleSave}>
                                                                        Save
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </Modal>
                                                    <Modal
                                                        isOpen={isDeleteModalOpen}
                                                        onRequestClose={closeDeleteModal}
                                                        contentLabel="Delete Modal"
                                                        className='editModal'
                                                    >
                                                        <div className='modulename-edit-form p-4 w-75 card'>
                                                            <form>
                                                                <label className='w-100 font-18'>
                                                                    Do you want to Delete Module?
                                                                </label>
                                                                <div className='d-flex justify-content-between align-items-center mt-4'>
                                                                    <button type="button" className='btn btn-md w-100 p-2 btn-secondary me-2' onClick={closeDeleteModal}>
                                                                        Cancel
                                                                    </button>
                                                                    <button type="button" className='btn btn-md w-100 p-2 btn-primary' onClick={handleSaveDelete}>
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </Modal>
                                                </li>
                                                <li>
                                                    <button className="dropdown-item" onClick={() => openDeleteModal(data)}>
                                                        <img src="/images/delete.png"></img>Delete
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>

                                        {isSubModuleInputVisible && (data.common_module_id === selectedSubModule) ?
                                            <div className='row mb-35'>
                                                <div className='col-md-12'>
                                                    <label htmlFor="submodule_title" className="form-label">Submodule Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="submodule_title"
                                                        placeholder="Enter submodule name"
                                                        value={submoduleName}
                                                        onChange={(e) => setSubmoduleName(e.target.value)}
                                                        onKeyDown={(e) => updateModule(e, true, data?.sub_modules.length + 1)}
                                                        required

                                                    />
                                                </div>
                                            </div>
                                            : null}

                                            
                                        {subModules?.map((sub_modules, index) => {

                                          {console.log('selected submodule:', selectedSubModule )}

                                            return <div key={index}><div onClick={() => getModuleContent(sub_modules)} className='sub module-box d-flex justify-content-between align-items-center' style={{ width: "calc(100% - 20px)", marginLeft: "20px" }}>
                                                <h5><img src="/images/module.svg"></img> {sub_modules.title}</h5>

                                                <div className="d-flex align-items-center justify-content-center text-decoration-none cursor_pointer dropdown-toggle" id="dropdownUser3" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="/images/add-more.png"></img>
                                                </div>

                                                <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser3">
                                                    {index > 0 &&
                                                    <li>
                                                        <button className="dropdown-item ps-3" onClick={() => moveSubModules(index, index - 1, subModules)}>
                                                            <img src="/images/arrow-up.png" width={18} height={20}></img>Move Up
                                                        </button>
                                                    </li>}
                                                    <li>
                                                        <button className="dropdown-item ps-3" onClick={() => handleAddSubModuleClick(sub_modules.common_module_id)}>
                                                            <img src="/images/add.png"></img>Add
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item ps-3" onClick={() => openEditModal(sub_modules)}>
                                                            <img src="/images/edit.svg" style={{ width: '18px' }}></img>Edit
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button className="dropdown-item ps-3" onClick={() => openDeleteModal(sub_modules)} >
                                                            <img src="/images/delete.png" ></img>Delete
                                                        </button>
                                                    </li>
                                                    {index < data?.sub_modules?.length - 1 && 
                                                    <li>
                                                        <button className="dropdown-item ps-3" onClick={() => moveSubModules(index, index + 1, subModules)}>
                                                            <img src="/images/arrow-down.png" width={18} height={20}></img>Move Down
                                                        </button>
                                                    </li>}
                                                </ul>
                                            </div>
                                                {isSubModuleInputVisible && (sub_modules.common_module_id === selectedSubModule) ?
                                                    <div className='row mb-35 mx-0' style={{ width: "calc(100% - 20px)", marginLeft: "20px" }}>
                                                        <div className='col-md-12'>
                                                            <label htmlFor="submodule_title" className="form-label">SubSubmodule Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="submodule_title"
                                                                placeholder="Enter submodule name"
                                                                value={submoduleName}
                                                                onChange={(e) => setSubmoduleName(e.target.value)}
                                                                onKeyDown={(e) => updateModule(e, true, sub_modules?.sub_sub_module.length + 1)}
                                                            />
                                                        </div>
                                                    </div>
                                                    : null}
                                                {sub_modules && sub_modules?.sub_sub_module?.map(sub_sub_module => {
                                                    return <div onClick={() => getModuleContent(sub_sub_module)} className='nested-sub module-box d-flex justify-content-between align-items-center' style={{ width: "calc(100% - 40px)", marginLeft: "40px" }}>
                                                        <h5><img src="/images/module.svg"></img> {sub_sub_module.title}</h5>
                                                        <div className="d-flex align-items-center justify-content-center text-decoration-none cursor_pointer dropdown-toggle" id="dropdownUser3" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <img src="/images/add-more.png"></img>
                                                        </div>

                                                        <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser3">

                                                            <li>
                                                                <button className="dropdown-item" onClick={() => openEditModal(sub_sub_module)}>
                                                                    <img src="/images/edit.svg" style={{ width: '18px' }}></img>Edit
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button className="dropdown-item" onClick={() => openDeleteModal(sub_sub_module)}>
                                                                    <img src="/images/delete.png" ></img>Delete
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                })}
                                            </div>
                                        })}
                                    </div>
                                })}
                            </div>
                            <div className='row mb-35 mx-0'>
                                <div className='col-md-12 add-module'>
                                    {isModuleInputVisible && (
                                        <div className='main-module'>
                                            <label htmlFor="module_name" className="form-label">Module Name</label>
                                            <input type="text" className="form-control" id="module_name" placeholder="Module Name" name="module_name" value={maduleName} onChange={(e) => setModuleName(e.target.value)} onKeyDown={(e) => updateModule(e, false, maduleList.length + 1)} />
                                        </div>
                                    )}
                                    <div className='d-flex justify-content-between w-100 align-items-center'>
                                        <button
                                            type="button"
                                            className='btn w-100 add_module'
                                            onClick={handleAddModuleClick}
                                        >
                                            + Add Module
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </aside>


            <div className='course_main'>
                {/* <button onClick={submitForm}>save data</button> */}
                <form>
                    
                    {/* <div className='row mb-4'>
                        <div className="col-md-6">
                            <label for="courseTitle" className="form-label">{courseName}</label>
                        </div>
                    </div> */}
                    
                    <div className='row mb-35'>
                        <div className="col-md-12">
                            <label for="course_title" className="form-label">Submodule Name</label>
                            <input type="text" className="form-control" id="course_title" placeholder="Introduction" value={selectedModule?.title} name="course_title" />
                        </div>
                    </div>

                    {selectedContentType === "content" ?

                        <div className='row mb-35'>
                        <div className='col-md-12'>
                            <div className='text'>
                            <div className='w-25'>
                                        <label htmlFor={``} className='col-form-label pt-0'>
                                            Content Type
                                        </label>
                                        <select
                                            id="testType"
                                            className='form-select'
                                            value={contentType}
                                            onChange={handleCOntetnTypeChange}
                                        >
                                            <option value='general'>General</option>
                                            <option value='copyright'>Copyright</option>
                                            <option value='feedback'>Feedback</option>
                                            <option value='last_module'>Last Module</option>
                                        </select>
                                    </div>
                                    <br/>
                                <div className='module_title'>
                                    <div className='d-flex justify-content-between align-items-center w-100'>
                                        <h5><img src="/images/module.svg"></img> <b className='fw-regular'>Text</b></h5>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <img src="/images/delete.svg"></img>
                                        </div>
                                    </div>
                                </div>
                                <CKEditor
                                    // disabled
                                    editor={ClassicEditor}
                                    data={courseContent}
                                    name="content"
                                    // config={ editorConfiguration }
                                    onReady={(editor) => {
                                        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                                            // Initialize your custom upload adapter with a function to be called on file upload
                                            return new MyUploadAdapter(loader, (e) => handleImageUpload(e, "content"));
                                        };
                                    }}
                                    onChange={(event, editor) => handleAdditionalEditorChange(editor.getData())}
                                />
                            </div>
                        </div>
                        </div>
                        : null
                    }
                    {selectedContentType === "video" ?
                        <div>
                            <div className='row mb-35'>
                                <div className='col-md-12'>
                                    <div className='video'>
                                        <div className='module_title'>
                                            <div className='d-flex justify-content-between align-items-center w-100'>
                                                <h5><img src="/images/module.svg"></img> <b className='fw-regular'>Video</b></h5>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    {/* <img src="/images/delete.svg"></img> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='video_upload'>
                                            <input type="file" accept="video/*" onChange={handleFileVChange} />
                                            {previewVUrl ? (
                                                <video
                                                    controls
                                                    width="200"
                                                    height="200"
                                                    style={{ marginTop: '10px' }}
                                                    key={URL.createObjectURL(selectedVFile)}
                                                >
                                                    <source src={URL.createObjectURL(selectedVFile)} type={selectedVFile.type} />
                                                    Your browser does not support the video tag.
                                                </video>
                                            ) :
                                                url ? <video
                                                    controls
                                                    width="200"
                                                    height="200"
                                                    style={{ marginTop: '10px' }}
                                                    key={url}
                                                >
                                                    <source src={url} type={url.type} />
                                                    Your browser does not support the video tag.
                                                </video>
                                                    : null

                                            }

                                        </div>
                                    </div>
                                    <button type='button' className='btn btn-md px-5 btn-primary mt-4 me-2' onClick={handleVideoUpload}>Upload</button>
                                </div>
                            </div>

                            <div className='row mb-35'>
                                <div className='col-md-12'>
                                    <div className='video'>
                                        <div className='module_title'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <h5><b className='fw-regular'>Add Transcripts</b></h5>
                                            </div>
                                        </div>
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={trance}
                                            name="trance"
                                            onReady={(editor) => {
                                                editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                                                    // Initialize your custom upload adapter with a function to be called on file upload
                                                    return new MyUploadAdapter(loader, (e) => handleImageUpload(e, "trance"));
                                                };
                                            }}
                                            onChange={(event, editor) => {
                                                handleTranceEditorChange(event, editor)
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div>
                                {markerForms?.map((marker, index) => (
                                    <div key={index} className='row mb-35'>
                                        <div className='col-md-12'>
                                            <div className='video'>
                                                <div className='module_title'>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <h5>
                                                            <b className='fw-regular'>Add Markers</b>
                                                        </h5>
                                                    </div>
                                                    <div className='d-flex justify-content-between align-items-center' onClick={() => deleteSection(marker)}>
                                                        <img src="/images/delete.svg"></img>
                                                    </div>
                                                </div>
                                                <div className='video_popup'>
                                                    <div className='row mb-4'>
                                                        <div className='col-md-4'>
                                                            <div className='row'>
                                                                <label htmlFor={`markerText-${index}`} className='col-sm-3 col-form-label'>
                                                                    {`Marker ${index + 1}`}
                                                                </label>
                                                                <div className='col-sm-9'>
                                                                    <input
                                                                        type='text'
                                                                        className='form-control'
                                                                        placeholder='00:00'
                                                                        id={`markerText-${index}`}
                                                                        value={marker.video_marking}
                                                                        onChange={(e) => {
                                                                            const updatedForms = [...markerForms];
                                                                            updatedForms[index].video_marking = e.target.value;
                                                                            setMarkerForms(updatedForms);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='col-md-4'>
                                                            <div className='row'>
                                                                <label htmlFor={`questionType-${index}`} className='col-sm-3 col-form-label'>
                                                                    Question Type
                                                                </label>
                                                                <div className='col-sm-9'>
                                                                    <select
                                                                        className='form-select'
                                                                        id={`questionType-${index}`}
                                                                        value={marker.question_type}
                                                                        onChange={(e) => {
                                                                            const updatedForms = [...markerForms];
                                                                            updatedForms[index].question_type = e.target.value;
                                                                            setMarkerForms(updatedForms);
                                                                        }}
                                                                    >
                                                                        <option value='single_choice'>Single Choice</option>
                                                                        <option value='multi_choice'>Multi Choice</option>
                                                                        <option value='para'>Paragraph</option>
                                                                        <option value='rating'>Rating</option>
                                                                        <option value='bool'>Boolean</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='col-md-4'>
                                                            <div className='row'>
                                                                <label htmlFor={`answerType-${index}`} className='col-sm-3 col-form-label'>
                                                                    Answer Type
                                                                </label>
                                                                <div className='col-sm-9'>
                                                                    <select
                                                                        className='form-select'
                                                                        id={`answerType-${index}`}
                                                                        value={marker.ans_view}
                                                                        onChange={(e) => {
                                                                            const updatedForms = [...markerForms];
                                                                            updatedForms[index].ans_view = e.target.value;
                                                                            setMarkerForms(updatedForms);
                                                                        }}
                                                                    >
                                                                        <option value="show_ans">Only Answer</option>
                                                                        <option value="show_ans_with_exp">Answer + Explanation</option>
                                                                        <option value="not_show_ans">Don’t Show Answer</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row mb-3'>
                                                        <div className='col-md-12'>
                                                            <div style={{ position: 'relative' }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={marker.content}
                                                                    name="Q"
                                                                    onReady={(editor) => {
                                                                        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                                                                            // Initialize your custom upload adapter with a function to be called on file upload
                                                                            return new MyUploadAdapter(loader, (e)=>handleImageUpload(e,"Q",index));
                                                                        };
                                                                    }}
                                                                    // onReady={(editor) => console.log('Editor is ready to use!', editor)}
                                                                    onChange={(event, editor) => handleEditorChange(event, editor, index)}
                                                                // onBlur={(event, editor) => console.log('Blur.', editor)}
                                                                // onFocus={(event, editor) => console.log('Focus.', editor)}
                                                                />
                                                                {/* <button type='button' className='img-upload' onClick={openModal}><img src='/images/image-upload-icon.svg' alt='img upload' ></img></button> */}
                                                            </div>

                                                            {/* <ImageUploadModal isOpen={isModalOpen} onClose={closeModal} /> */}

                                                        </div>
                                                    </div>




                                                    {marker.question_type === 'para' && marker.sub_questions?.map((sub_questions, subIndex) => (
                                                        <div key={subIndex} className='row mb-35'>
                                                            <div className='col-md-12'>
                                                                <div className='video'>
                                                                    <div className='module_title'>
                                                                        <div className='d-flex justify-content-between align-items-center'>
                                                                            <h5>
                                                                                <b className='fw-regular'>Add Markers</b>
                                                                            </h5>
                                                                        </div>
                                                                        <div className='d-flex justify-content-between align-items-center' onClick={() => deleteSection(sub_questions)}>
                                                                            <img src="/images/delete.svg"></img>
                                                                        </div>
                                                                    </div>
                                                                    <div className='video_popup'>
                                                                        <div className='row mb-4'>
                                                                            <div className='col-md-4'>
                                                                                <div className='row'>
                                                                                    <label htmlFor={`markerText-${index}`} className='col-sm-3 col-form-label'>
                                                                                        {`Marker ${index + 1}`}
                                                                                    </label>
                                                                                    <div className='col-sm-9'>
                                                                                        <input
                                                                                            type='text'
                                                                                            className='form-control'
                                                                                            placeholder='00:00'
                                                                                            id={`markerText-${index}`}
                                                                                            value={sub_questions.video_marking}
                                                                                            onChange={(e) => {
                                                                                                const updatedForms = [...markerForms];
                                                                                                updatedForms[index].sub_questions[subIndex].video_marking = e.target.value;
                                                                                                setMarkerForms(updatedForms);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className='col-md-4'>
                                                                                <div className='row'>
                                                                                    <label htmlFor={`questionType-${index}`} className='col-sm-3 col-form-label'>
                                                                                        Question Type
                                                                                    </label>
                                                                                    <div className='col-sm-9'>
                                                                                        <select
                                                                                            className='form-select'
                                                                                            id={`questionType-${index}`}
                                                                                            value={sub_questions.question_type}
                                                                                            onChange={(e) => {
                                                                                                const updatedForms = [...markerForms];
                                                                                                updatedForms[index].sub_questions[subIndex].question_type = e.target.value;
                                                                                                setMarkerForms(updatedForms);
                                                                                            }}
                                                                                        >
                                                                                            <option value='single_choice'>Single Choice</option>
                                                                                            <option value='multi_choice'>Multi Choice</option>
                                                                                            {/* <option value='para'>Paragraph</option> */}
                                                                                            {/* <option value='rating'>Rating</option> */}
                                                                                            {/* <option value='bool'>Boolean</option> */}
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className='col-md-4'>
                                                                                <div className='row'>
                                                                                    <label htmlFor={`answerType-${index}`} className='col-sm-3 col-form-label'>
                                                                                        Answer Type
                                                                                    </label>
                                                                                    <div className='col-sm-9'>
                                                                                        <select
                                                                                            className='form-select'
                                                                                            id={`answerType-${index}`}
                                                                                            value={sub_questions.ans_view}
                                                                                            onChange={(e) => {
                                                                                                const updatedForms = [...markerForms];
                                                                                                updatedForms[index].sub_questions[subIndex].ans_view = e.target.value;
                                                                                                setMarkerForms(updatedForms);
                                                                                            }}
                                                                                        >
                                                                                            <option value="show_ans">Only Answer</option>
                                                                                            <option value="show_ans_with_exp">Answer + Explanation</option>
                                                                                            <option value="not_show_ans">Don’t Show Answer</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className='row mb-3'>
                                                                            <div className='col-md-12'>
                                                                                <div style={{ position: 'relative' }}>
                                                                                    <CKEditor
                                                                                        editor={ClassicEditor}
                                                                                        data={sub_questions.content}
                                                                                        name="Q"
                                                                                        onChange={(event, editor) => handleSubEditorChange(event, editor, index, subIndex)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row mb-3'>
                                                                            <div className='col-md-12'>
                                                                                {sub_questions.options.map((option, suboptionIndex) => (
                                                                                    <div className=''>
                                                                                        <p className='my-2'>{suboptionIndex + 1}</p>
                                                                                    <div key={suboptionIndex} className='form-check check-option'>
                                                                                        <input
                                                                                            className='form-check-input'
                                                                                            type={sub_questions.question_type === 'single_choice' ? 'radio' : 'checkbox'}
                                                                                            value={option?.content}
                                                                                            id={`option-${suboptionIndex}-${index}`}
                                                                                            checked={option.is_correct || false}
                                                                                            onChange={(e) => handleSubCheckboxChange(e, index, subIndex, suboptionIndex)}
                                                                                        />
                                                                                        {/* <label className='form-check-label pl-20' htmlFor={`option-${optionIndex}-${index}`}> */}

                                                                                        <div style={{ paddingLeft: "20px", position: 'relative' }}>
                                                                                            <CKEditor
                                                                                                editor={ClassicEditor}
                                                                                                data={option?.content}
                                                                                                // config={editorConfiguration}
                                                                                                onReady={(editor) => console.log('Editor is ready to use!', editor)}
                                                                                                onChange={(event, editor) => handlesubCKEditorChange(index, suboptionIndex, event, editor, subIndex)}
                                                                                                onBlur={(event, editor) => console.log('Blur.', editor)}
                                                                                                onFocus={(event, editor) => console.log('Focus.', editor)}
                                                                                            />
                                                                                        </div>
                                                                                        {/* </label> */}
                                                                                    </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                        <div className='row mb-3'>
                                                                            <div className='col-md-6'>
                                                                                <button className='btn btn-outline-secondary' type='button' onClick={() => handleParaAddOption(index, subIndex)}>
                                                                                    + Add Option
                                                                                </button>
                                                                            </div>
                                                                        </div>

                                                                        <div className='row mb-3'>
                                                                            <div className='col-md-12'>
                                                                                <label htmlFor={`correctAnswerExplanation-${index}`} className='col-sm-3 col-form-label'>
                                                                                    Correct answer explanation
                                                                                </label>
                                                                                <div className='col-sm-12'>
                                                                                    <input
                                                                                        type='text'
                                                                                        className='form-control'
                                                                                        id={`correctAnswerExplanation-${index}`}
                                                                                        value={sub_questions.crt_ans_exp}
                                                                                        onChange={(e) => handleSubCorrectAnswerExplanationChange(e, subIndex, index)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className='row mb-3'>
                                                                            <div className='col-md-12'>
                                                                                <label htmlFor={`wrongAnswerExplanation-${index}`} className='col-sm-3 col-form-label'>
                                                                                    Wrong answer explanation
                                                                                </label>
                                                                                <div className='col-sm-12'>
                                                                                    <input
                                                                                        type='text'
                                                                                        className='form-control'
                                                                                        id={`wrongAnswerExplanation-${index}`}
                                                                                        value={sub_questions.wrng_ans_exp}
                                                                                        onChange={(e) => handleSubWrongAnswerExplanationChange(e, subIndex, index)}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className='row mb-3'>
                                                                            <div className='col-md-6'>
                                                                                {subIndex === marker.sub_questions?.length - 1 && (
                                                                                    <button type="button" className='add_markers_button' onClick={(e) => handleSubAddMarkerForm(index, marker.sub_questions.length + 1)}>
                                                                                        Add Marker Section
                                                                                    </button>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {marker.question_type === 'rating' && <>
                                                        <div className='col-md-12'>
                                                            <label htmlFor={`min-${index}`} className='col-sm-3 col-form-label'>
                                                                minimum value
                                                            </label>
                                                            <div className='col-sm-12'>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    id={`min-${index}`}
                                                                    value={marker?.rating?.min}
                                                                    onChange={(e) => handleminChange(e, index)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <label htmlFor={`maximum-${index}`} className='col-sm-3 col-form-label'>
                                                                maximum value
                                                            </label>
                                                            <div className='col-sm-12'>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    id={`maximum-${index}`}
                                                                    value={marker?.rating?.max}
                                                                    onChange={(e) => handlemaxChange(e, index)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <label htmlFor={`interval-${index}`} className='col-sm-3 col-form-label'>
                                                                Interval value
                                                            </label>
                                                            <div className='col-sm-12'>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    id={`interval-${index}`}
                                                                    value={marker?.rating?.interval}
                                                                    onChange={(e) => handleintervalChange(e, index)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <label htmlFor={`Answer-${index}`} className='col-sm-3 col-form-label'>
                                                                Answer value
                                                            </label>
                                                            <div className='col-sm-12'>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    id={`Answer-${index}`}
                                                                    value={marker?.rating?.ans}
                                                                    onChange={(e) => handleansChange(e, index)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>}
                                                    {(marker.question_type === 'single_choice' || marker.question_type === 'multi_choice') && <div className='row mb-3'>
                                                        <div className='col-md-12'>
                                                            {marker.options.map((option, optionIndex) => (
                                                                <div className=''>
                                                                    <p className='my-2'>Option {optionIndex + 1}</p>
                                                                <div key={optionIndex} className='form-check check-option'>
                                                                    <input
                                                                        className='form-check-input'
                                                                        type={marker.question_type === 'single_choice' ? 'radio' : 'checkbox'}
                                                                        value={option?.content}
                                                                        id={`option-${optionIndex}-${index}`}
                                                                        checked={option.is_correct || false}
                                                                        onChange={(e) => handleCheckboxChange(e, index)}
                                                                    />
                                                                    {/* <label className='form-check-label pl-20' htmlFor={`option-${optionIndex}-${index}`}> */}

                                                                    <div style={{ paddingLeft: "20px", position: 'relative' }}>
                                                                        <CKEditor
                                                                            editor={ClassicEditor}
                                                                            data={option?.content}
                                                                            // config={editorConfiguration}
                                                                            // onReady={(editor) => console.log('Editor is ready to use!', editor)}
                                                                            onReady={(editor) => {
                                                                                editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                                                                                    // Initialize your custom upload adapter with a function to be called on file upload
                                                                                    return new MyUploadAdapter(loader, (e)=>handleImageUpload(e,"O",index,optionIndex));
                                                                                };
                                                                            }}
                                                                            onChange={(event, editor) => handleCKEditorChange(optionIndex, event, editor, index)}
                                                                            // onBlur={(event, editor) => console.log('Blur.', editor)}
                                                                            // onFocus={(event, editor) => console.log('Focus.', editor)}
                                                                        />
                                                                    </div>


                                                                    {/* </label> */}
                                                                </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>}
                                                    {(marker.question_type === 'single_choice' || marker.question_type === 'multi_choice') && <div className='row mb-3'>
                                                        <div className='col-md-6'>
                                                            <button className='btn btn-outline-secondary' type='button' onClick={() => handleAddOption(index)}>
                                                                + Add Option
                                                            </button>
                                                        </div>
                                                    </div>}

                                                    <div className='row mb-3'>
                                                        <div className='col-md-12'>
                                                            <label htmlFor={`correctAnswerExplanation-${index}`} className='col-sm-3 col-form-label'>
                                                                Correct answer explanation
                                                            </label>
                                                            <div className='col-sm-12'>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    id={`correctAnswerExplanation-${index}`}
                                                                    value={marker.crt_ans_exp}
                                                                    onChange={(e) => handleCorrectAnswerExplanationChange(e, index)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row mb-3'>
                                                        <div className='col-md-12'>
                                                            <label htmlFor={`wrongAnswerExplanation-${index}`} className='col-sm-3 col-form-label'>
                                                                Wrong answer explanation
                                                            </label>
                                                            <div className='col-sm-12'>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    id={`wrongAnswerExplanation-${index}`}
                                                                    value={marker.wrng_ans_exp}
                                                                    onChange={(e) => handleWrongAnswerExplanationChange(e, index)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row mb-3'>
                                                        <div className='col-md-6'>
                                                            {index === markerForms.length - 1 && (
                                                                <button className='add_markers_button' onClick={(e) => handleAddMarkerForm(markerForms.length + 1)}>
                                                                    Add Marker Section
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div> : null}

                    {selectedContentType === "test" ? <div>
                    <div className='row mb-35'>
                            <div className='col-md-12'>
                                <div className='text'>

                                    <div className='d-flex justify-content-between align-items-center mb-35'>
                                        <div className='w-25'>
                                            <label htmlFor={``} className='col-form-label pt-0'>
                                                Test Type
                                            </label>
                                            <select
                                                id="testType"
                                                className='form-select'
                                                value={testType}
                                                onChange={handleTestTypeChange}
                                            >
                                                <option value='base_line'>Base Line</option>
                                                <option value='general_line'>General Line</option>
                                                <option value='end_line'>End Line</option>
                                            </select>
                                        </div>

                                        <div className='w-25'>
                                            <label htmlFor="isEligible" className='col-form-label pt-0'>
                                                Is Skippable ?
                                            </label>
                                            <select
                                                id="isEligible"
                                                className='form-select'
                                                value={isEligible}
                                                onChange={handleEligibilityChange}
                                            >
                                                <option value={true}>Yes</option>
                                                <option value={false}>No</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                    <div className='row mb-4'>
                                                <div className="col-md-12">
                                                    <label for="tags" className="form-label">Tags</label>
                                                    <input type="text" className="form-control" id="tags" placeholder="tags" value={tags} name="tags" onChange={(e) => setTags(e.target.value)} />
                                                </div>
                                            </div>

                                    <div className='module_title'>
                                        <div className='d-flex justify-content-between align-items-center w-100'>
                                            <h5><img src="/images/module.svg"></img> <b className='fw-regular'>Instruction</b></h5>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <img src="/images/delete.svg"></img>
                                            </div>
                                        </div>
                                    </div>
                                    <CKEditor
                                        // disabled
                                        editor={ClassicEditor}
                                        data={courseContent}
                                        name="content"
                                        // config={ editorConfiguration }
                                        onReady={(editor) => {
                                            editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                                                // Initialize your custom upload adapter with a function to be called on file upload
                                                return new MyUploadAdapter(loader, (e) => handleImageUpload(e, "content"));
                                            };
                                        }}
                                        onChange={(event, editor) => handleAdditionalEditorChange(editor.getData())}
                                    />
                                </div>
                            </div>
                        </div>

                    <div className='row mb-35'>
                        <div className="col-md-12 mb-4">
                            <label for="Max_attempt" className="form-label">Maximum Attempt <span className='red'>*</span></label>
                            <input type="text" className="form-control" id="Max_attempt" placeholder="Maximum Attempt Number" value={maxAttempt} name="Max_Attempt" onChange={(e)=>setMaxAttempt(e.target.value)} required/>
                        </div>
                        <div className="col-md-12">
                            <label for="Passing_Percentages" className="form-label">Passing Percentage <span className='red'>*</span></label>
                            <input type="text" className="form-control" id="Passing_Percentages" placeholder="Passing Percentage Number" value={passingPercentages} name="Passing_Percentages" onChange={(e)=>setPassingPercentages(e.target.value)} required/>
                        </div>
                    </div>

                        {markerForms?.map((marker, index) => (
                            <div key={index} className='row mb-35'>
                                <div className='col-md-12'>
                                    <div className='video'>
                                        <div className='module_title'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <h5>
                                                    <b className='fw-regular'>{index + 1}. Add Question</b>
                                                </h5>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-center' onClick={() => deleteSection(marker)}>
                                                <img src="/images/delete.svg"></img>
                                            </div>

                                        </div>
                                        <div className='video_popup'>
                                            <div className='row mb-3'>
                                                <div className='col-md-4'>
                                                    <div className='row'>
                                                        <label htmlFor={`markerText-${index}`} className='col-sm-3 col-form-label'>
                                                            Marks
                                                        </label>
                                                        <div className='col-sm-9'>
                                                            <input
                                                                type='text'
                                                                className='form-control'
                                                                placeholder='numbers'
                                                                id={`markerText-${index}`}
                                                                value={marker.marks}
                                                                onChange={(e) => {
                                                                    const updatedForms = [...markerForms];
                                                                    updatedForms[index].marks = e.target.value;
                                                                    setMarkerForms(updatedForms);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-md-4'>
                                                    <div className='row'>
                                                        <label htmlFor={`questionType-${index}`} className='col-sm-3 col-form-label'>
                                                            Question Type
                                                        </label>
                                                        <div className='col-sm-9'>
                                                            <select
                                                                className='form-select'
                                                                id={`questionType-${index}`}
                                                                value={marker.question_type}
                                                                onChange={(e) => {
                                                                    const updatedForms = [...markerForms];
                                                                    updatedForms[index].question_type = e.target.value;
                                                                    setMarkerForms(updatedForms);
                                                                }}
                                                            >
                                                                <option value='single_choice'>Single Choice</option>
                                                                <option value='multi_choice'>Multi Choice</option>
                                                                <option value='para'>Paragraph</option>
                                                                <option value='rating'>Rating</option>
                                                                <option value='bool'>Boolean</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-md-4'>
                                                    <div className='row'>
                                                        <label htmlFor={`answerType-${index}`} className='col-sm-3 col-form-label'>
                                                            Answer Type
                                                        </label>
                                                        <div className='col-sm-9'>
                                                            <select
                                                                className='form-select'
                                                                id={`answerType-${index}`}
                                                                value={marker.ans_view}
                                                                onChange={(e) => {
                                                                    const updatedForms = [...markerForms];
                                                                    updatedForms[index].ans_view = e.target.value;
                                                                    setMarkerForms(updatedForms);
                                                                }}
                                                            >
                                                                <option value="show_ans">Only Answer</option>
                                                                <option value="show_ans_with_exp">Answer + Explanation</option>
                                                                <option value="not_show_ans">Don’t Show Answer</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                           {/* added the question tag */}
                                            <div className='row mb-4'>
                                                <div className="col-md-12">
                                                    <label htmlFor={`tags-${index}`} className="form-label">Tags</label>
                                                    <input type="text" className="form-control" id={`tags-${index}`} placeholder="tags" value={marker.question_tag} name="tags" onChange={(e) => {
                                                        const updatedForms = [...markerForms];
                                                        updatedForms[index].question_tag = e.target.value;
                                                        setMarkerForms(updatedForms);
                                                    }} />
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <div className='col-md-12'>
                                                    <CKEditor
                                                        editor={ClassicEditor}
                                                        data={marker.content}
                                                        // onReady={(editor) => console.log('Editor is ready to use!', editor)}
                                                        name="Q"
                                                                    onReady={(editor) => {
                                                                        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                                                                            // Initialize your custom upload adapter with a function to be called on file upload
                                                                            return new MyUploadAdapter(loader, (e)=>handleImageUpload(e,"Q",index));
                                                                        };
                                                                    }}
                                                        onChange={(event, editor) => handleEditorChange(event, editor, index)}
                                                        onBlur={(event, editor) => console.log('Blur.', editor)}
                                                        onFocus={(event, editor) => console.log('Focus.', editor)}
                                                    />
                                                </div>
                                            </div>



                                            {marker.question_type === 'para' && marker.sub_questions?.map((sub_questions, subIndex) => (
                                                <div key={subIndex} className='row mb-35'>
                                                    <div className='col-md-12'>
                                                        <div className='video'>
                                                            <div className='module_title'>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <h5>
                                                                        <b className='fw-regular'>Add Question</b>
                                                                    </h5>
                                                                </div>
                                                                <div className='d-flex justify-content-between align-items-center' onClick={() => deleteSection(sub_questions)}>
                                                                    <img src="/images/delete.svg"></img>
                                                                </div>
                                                            </div>
                                                            <div className='video_popup'>
                                                                <div className='row mb-4'>
                                                                    <div className='col-md-4'>
                                                                        <div className='row'>
                                                                            <label htmlFor={`markerText-${index}`} className='col-sm-3 col-form-label'>
                                                                                {`Marker ${index + 1}`}
                                                                            </label>
                                                                            <div className='col-sm-9'>
                                                                                <input
                                                                                    type='text'
                                                                                    className='form-control'
                                                                                    placeholder='00:00'
                                                                                    id={`markerText-${index}`}
                                                                                    value={sub_questions.video_marking}
                                                                                    onChange={(e) => {
                                                                                        const updatedForms = [...markerForms];
                                                                                        updatedForms[index].sub_questions[subIndex].video_marking = e.target.value;
                                                                                        setMarkerForms(updatedForms);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-md-4'>
                                                                        <div className='row'>
                                                                            <label htmlFor={`questionType-${index}`} className='col-sm-3 col-form-label'>
                                                                                Question Type
                                                                            </label>
                                                                            <div className='col-sm-9'>
                                                                                <select
                                                                                    className='form-select'
                                                                                    id={`questionType-${index}`}
                                                                                    value={sub_questions.question_type}
                                                                                    onChange={(e) => {
                                                                                        const updatedForms = [...markerForms];
                                                                                        updatedForms[index].sub_questions[subIndex].question_type = e.target.value;
                                                                                        setMarkerForms(updatedForms);
                                                                                    }}
                                                                                >
                                                                                    <option value='single_choice'>Single Choice</option>
                                                                                    <option value='multi_choice'>Multi Choice</option>
                                                                                    {/* <option value='para'>Paragraph</option> */}
                                                                                    {/* <option value='rating'>Rating</option>
                                                                                    <option value='bool'>Boolean</option> */}
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-md-4'>
                                                                        <div className='row'>
                                                                            <label htmlFor={`answerType-${index}`} className='col-sm-3 col-form-label'>
                                                                                Answer Type
                                                                            </label>
                                                                            <div className='col-sm-9'>
                                                                                <select
                                                                                    className='form-select'
                                                                                    id={`answerType-${index}`}
                                                                                    value={sub_questions.ans_view}
                                                                                    onChange={(e) => {
                                                                                        const updatedForms = [...markerForms];
                                                                                        updatedForms[index].sub_questions[subIndex].ans_view = e.target.value;
                                                                                        setMarkerForms(updatedForms);
                                                                                    }}
                                                                                >
                                                                                    <option value="show_ans">Only Answer</option>
                                                                                    <option value="show_ans_with_exp">Answer + Explanation</option>
                                                                                    <option value="not_show_ans">Don’t Show Answer</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='row mb-3'>
                                                                    <div className='col-md-12'>
                                                                        <div className=''>
                                                                           {/* <p className='question-number me-2'>Q.{subIndex + 1}.</p> */}
                                                                        <div className='w-100' style={{ position: 'relative' }}>
                                                                            <CKEditor
                                                                                editor={ClassicEditor}
                                                                                data={sub_questions.content}
                                                                                name="Q"
                                                                                onChange={(event, editor) => handleSubEditorChange(event, editor, index, subIndex)}
                                                                            />
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='row mb-3'>
                                                                    <div className='col-md-12'>
                                                                        {sub_questions.options.map((option, suboptionIndex) => (
                                                                            <div className=''>
                                                                                <p className='option-number me-2 my-2'>Option {suboptionIndex + 1}</p>
                                                                            <div key={suboptionIndex} className='form-check check-option w-100'>
                                                                                <input
                                                                                    className='form-check-input'
                                                                                    type={sub_questions.question_type === 'single_choice' ? 'radio' : 'checkbox'}
                                                                                    value={option?.content}
                                                                                    id={`option-${suboptionIndex}-${index}`}
                                                                                    checked={option.is_correct || false}
                                                                                    onChange={(e) => handleSubCheckboxChange(e, index, subIndex, suboptionIndex)}
                                                                                />
                                                                                {/* <label className='form-check-label pl-20' htmlFor={`option-${optionIndex}-${index}`}> */}

                                                                                <div style={{ paddingLeft: "20px", position: 'relative' }}>
                                                                                    <CKEditor
                                                                                        editor={ClassicEditor}
                                                                                        data={option?.content}
                                                                                        // config={editorConfiguration}
                                                                                        onReady={(editor) => console.log('Editor is ready to use!', editor)}
                                                                                        onChange={(event, editor) => handlesubCKEditorChange(index, suboptionIndex, event, editor, subIndex)}
                                                                                        onBlur={(event, editor) => console.log('Blur.', editor)}
                                                                                        onFocus={(event, editor) => console.log('Focus.', editor)}
                                                                                    />
                                                                                </div>
                                                                                {/* </label> */}
                                                                            </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <div className='row mb-3'>
                                                                    <div className='col-md-6'>
                                                                        <button className='btn btn-outline-secondary' type='button' onClick={() => handleParaAddOption(index, subIndex)}>
                                                                            + Add Option
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                                <div className='row mb-3'>
                                                                    <div className='col-md-12'>
                                                                        <label htmlFor={`correctAnswerExplanation-${index}`} className='col-sm-3 col-form-label'>
                                                                            Correct answer explanation
                                                                        </label>
                                                                        <div className='col-sm-12'>
                                                                            <input
                                                                                type='text'
                                                                                className='form-control'
                                                                                id={`correctAnswerExplanation-${index}`}
                                                                                value={sub_questions.crt_ans_exp}
                                                                                onChange={(e) => handleSubCorrectAnswerExplanationChange(e, subIndex, index)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='row mb-3'>
                                                                    <div className='col-md-12'>
                                                                        <label htmlFor={`wrongAnswerExplanation-${index}`} className='col-sm-3 col-form-label'>
                                                                            Wrong answer explanation
                                                                        </label>
                                                                        <div className='col-sm-12'>
                                                                            <input
                                                                                type='text'
                                                                                className='form-control'
                                                                                id={`wrongAnswerExplanation-${index}`}
                                                                                value={sub_questions.wrng_ans_exp}
                                                                                onChange={(e) => handleSubWrongAnswerExplanationChange(e, subIndex, index)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='row mb-3'>
                                                                    <div className='col-md-6'>
                                                                        {subIndex === marker.sub_questions?.length - 1 && (
                                                                            <button type="button" className='add_markers_button' onClick={(e) => handleSubAddMarkerForm(index, marker.sub_questions.length + 1)}>
                                                                                Add Marker Section
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            {marker.question_type === 'rating' && <>
                                                <div className='col-md-12'>
                                                    <label htmlFor={`min-${index}`} className='col-sm-3 col-form-label'>
                                                        minimum value
                                                    </label>
                                                    <div className='col-sm-12'>
                                                        <input
                                                            type='number'
                                                            className='form-control'
                                                            id={`min-${index}`}
                                                            value={marker?.rating?.min}
                                                            onChange={(e) => handleminChange(e, index)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <label htmlFor={`maximum-${index}`} className='col-sm-3 col-form-label'>
                                                        maximum value
                                                    </label>
                                                    <div className='col-sm-12'>
                                                        <input
                                                            type='number'
                                                            className='form-control'
                                                            id={`maximum-${index}`}
                                                            value={marker?.rating?.max}
                                                            onChange={(e) => handlemaxChange(e, index)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <label htmlFor={`interval-${index}`} className='col-sm-3 col-form-label'>
                                                        Interval value
                                                    </label>
                                                    <div className='col-sm-12'>
                                                        <input
                                                            type='number'
                                                            className='form-control'
                                                            id={`interval-${index}`}
                                                            value={marker?.rating?.interval}
                                                            onChange={(e) => handleintervalChange(e, index)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <label htmlFor={`Answer-${index}`} className='col-sm-3 col-form-label'>
                                                        Answer value
                                                    </label>
                                                    <div className='col-sm-12'>
                                                        <input
                                                            type='number'
                                                            className='form-control'
                                                            id={`Answer-${index}`}
                                                            value={marker?.rating?.ans}
                                                            onChange={(e) => handleansChange(e, index)}
                                                        />
                                                    </div>
                                                </div>
                                            </>}
                                            {(marker.question_type === 'single_choice' || marker.question_type === 'multi_choice') && <div className='row mb-3'>
                                                <div className='col-md-12'>
                                                    {marker.options.map((option, optionIndex) => (
                                                    
                                                        <div className=''>
                                                            <p className='option-number pe-2 my-2'>Option {optionIndex + 1}</p>
                                                        <div key={optionIndex} className='form-check check-option'>
                                                            <input
                                                                className='form-check-input'
                                                                type={marker.question_type === 'single_choice' ? 'radio' : 'checkbox'}
                                                                value={option?.content}
                                                                id={`option-${optionIndex}-${index}`}
                                                                checked={option.is_correct || false}
                                                                onChange={(e) => handleCheckboxChange(e, index)}
                                                            />
                                                            {/* <label className='form-check-label pl-20' htmlFor={`option-${optionIndex}-${index}`}> */}

                                                            <div style={{ paddingLeft: "20px" }}>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={option?.content}
                                                                    onReady={(editor) => {
                                                                        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                                                                            // Initialize your custom upload adapter with a function to be called on file upload
                                                                            return new MyUploadAdapter(loader, (e)=>handleImageUpload(e,"O",index,optionIndex));
                                                                        };
                                                                    }}
                                                                    // onReady={(editor) => console.log('Editor is ready to use!', editor)}
                                                                    onChange={(event, editor) => handleCKEditorChange(optionIndex, event, editor, index)}
                                                                    onBlur={(event, editor) => console.log('Blur.', editor)}
                                                                    onFocus={(event, editor) => console.log('Focus.', editor)}
                                                                />
                                                            </div>
                                                            {/* </label> */}
                                                        </div>
                                                        </div>
                                                      
                                                    ))}
                                                </div>
                                            </div>}

                                            {(marker.question_type === 'single_choice' || marker.question_type === 'multi_choice') && <div className='row mb-3'>
                                                <div className='col-md-6'>
                                                    <button className='btn btn-outline-secondary' type='button' onClick={() => handleAddOption(index)}>
                                                        + Add Option
                                                    </button>
                                                </div>
                                                {/* <div className='col-md-6'>
                                                      <div className='row'>
                                                          <label htmlFor={`correctAnswer-${index}`} className='col-sm-3 col-form-label'>
                                                              Correct Answer
                                                          </label>
                                                          <div className='col-sm-9'>
                                                              <select
                                                                  className='form-select'
                                                                  id={`correctAnswer-${index}`}
                                                                  value={marker.correctAnswer}
                                                                  // onChange={(e) => handleCorrectAnswerChange(e, index)}
                                                              >
                                                                  {marker.options.map((option, optionIndex) => (
                                                                      <option key={optionIndex}>{option?.content}</option>
                                                                  ))}
                                                              </select>
                                                          </div>
                                                      </div>
                                                  </div> */}
                                            </div>}
                                            <div className='row mb-3'>
                                                <div className='col-md-12'>
                                                    <label htmlFor={`correctAnswerExplanation-${index}`} className='col-sm-3 col-form-label'>
                                                        Correct answer explanation
                                                    </label>
                                                    <div className='col-sm-12'>
                                                        <input
                                                            type='text'
                                                            className='form-control'
                                                            id={`correctAnswerExplanation-${index}`}
                                                            value={marker.crt_ans_exp}
                                                            onChange={(e) => handleCorrectAnswerExplanationChange(e, index)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-3'>
                                                <div className='col-md-12'>
                                                    <label htmlFor={`wrongAnswerExplanation-${index}`} className='col-sm-3 col-form-label'>
                                                        Wrong answer explanation
                                                    </label>
                                                    <div className='col-sm-12'>
                                                        <input
                                                            type='text'
                                                            className='form-control'
                                                            id={`wrongAnswerExplanation-${index}`}
                                                            value={marker.wrng_ans_exp}
                                                            onChange={(e) => handleWrongAnswerExplanationChange(e, index)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mb-3'>
                                                <div className='col-md-6'>
                                                    {index === markerForms.length - 1 && (
                                                        <button className='add_markers_button' onClick={() => handleAddMarkerForm(markerForms.length + 1)}>
                                                            Add Question
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> : null}
                </form>
                <div className='course_component'>
                    <div className='d-flex mb-0'>
                        <div className='course_content_menu col' onClick={() => { setSelectedContentType('content'); reset() }}>
                            <img src="/images/font.png" className='mb-1 content_icon'></img>
                            <p className='mb-0'>Add Text</p>
                        </div>
                        <div className='course_content_menu col' onClick={() => { setSelectedContentType('video'); reset() }}>
                            <img src="/images/video-player.png" className='mb-1 content_icon'></img>
                            <p className='mb-0'>Add Video</p>
                        </div>
                        <div className='course_content_menu col' onClick={() => { setSelectedContentType('test'); reset() }}>
                            <img src="/images/idea.png" className='mb-1 content_icon'></img>
                            <p className='mb-0'>Test</p>
                        </div>
                        <div className='course_content_menu col'>
                            <img src="/images/share.png" className='mb-1 content_icon'></img>
                            <p className='mb-0'>Snippet</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CourseContent;
